/* eslint-disable max-len */
import { RISK_SECTION_HIGH, RISK_SECTION_LOW } from '@consts/risk';
import {
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 0, 1),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 2, 99999),
];

export const resultSettings = [
  formatResult(
    'Du bedöms, baserat på den information du gett, ha låg risk för att ramla. Känner du dig ostadig och upplever att du har försämrad balans rekommenderar vi dig att du vänder dig till din vårdgivare för vidare utredning och eventuella åtgärder.',
    '',
    0,
    1,
  ),
  formatResult(
    'Du bedöms ha ökad risk för att ramla. Upplever du att din balans har blivit sämre och att du känner dig ostadig när du rör på dig, är det viktigt att söka vård. En professionell bedömning kan identifiera underliggande orsaker och skapa en individuell plan för rehabilitering.',
    'Vi rekommenderar att du söker vård för att få hjälp att förbättra din balans och styrka genom individanpassad träning. Det minskar inte bara risken för fall, utan främjar också din generella hälsa och välbefinnande.',
    2,
    99999,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Har du fallit (oavsiktligt hamnat på marken) under de senaste 12 månaderna?',
    2,
    0,
    0,
    0,
    'Personer som fallit en gång har större risk att falla igen.',
  ),
  formatYesNoQuestion(
    'question2',
    'Använder du något gånghjälpmedel såsom en käpp eller rullator?',
  ),
  formatYesNoQuestion(
    'question3',
    'Känner du dig ostadig när du står eller går?',
    2,
    0,
    0,
    0,
    'Ostadighet kan vara ett tecken på nedsatt balans, vilket är en riskfaktor för att falla.',
  ),
  formatYesNoQuestion(
    'question4',
    'Är du orolig att du ska ramla?',
    2,
    0,
    0,
    0,
    'Om du är orolig för att falla är det mer sannolikt att du kommer att falla.',
  ),
  formatYesNoQuestion(
    'question5',
    'Behöver du använda händerna för att trycka dig upp när du reser dig från en stol?',
    1,
    0,
    0,
    0,
    'Detta kan vara ett tecken på svaga benmuskler, vilket är en riskfaktor för att falla.',
  ),
  formatYesNoQuestion(
    'question6',
    'Tar du medicin som ibland får dig att känna dig yr, svimfärdig eller trött?',
    1,
    0,
    0,
    0,
    'Biverkningar av medicinering kan öka risken för att ramla.',
  ),
  formatYesNoQuestion(
    'question7',
    'Känner du regelbundet ett behov av att skynda till toaletten?',
    1,
    0,
    0,
    0,
    'Att skynda till badrummet, särskilt om det händer på natten, kan vara en risk för att du faller.',
  ),
  formatYesNoQuestion(
    'question8',
    'Använder du dina möbler som stöd när du rör dig i ditt hem?',
    1,
    0,
    0,
    0,
    'Att använda möbler för att hålla sig upprätt hemma kan vara ett tecken på dålig balans.',
  ),
];

export const references = [];
